<template>
  <div class="MySignal">
    <!-- <p class="pageTop"></p> -->
    <div class="PageCommon">
      <div class="bscroll" ref="bscroll">
          <div class="bscroll-container">
            <h3 class="pageTitle">
              <span v-if="!mobileFlag">
                  {{ $t("MyFollowers_MySignalSource") }}
              </span>
              <div>
                
              </div>

            </h3>

            <div class="tabDiv">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane :label="$t('signalOn')" name="on">
                  </el-tab-pane>
                  <el-tab-pane :label="$t('signalOff')" name="off">
                  </el-tab-pane>
                </el-tabs>
              </div>

            <div class="mobileFilterDiv" v-if="mobileFlag">
              <el-button class="mobileFilterBtn"   @click="addSignal(true)"  type="text" size="small">
                {{ $t('MyFollowers_AddSignalSource') }}
                </el-button>
                <el-button class="mobileFilterBtn"  @click="getData(currentPage,pageSize)"  type="text" size="small" >
                {{ $t('ManualReplenishment_refresh') }}
                </el-button>
                    <el-button class="mobileFilterBtn"  @click="openSearch()"  type="text" size="small">
                      {{ $t("mobileFilterBtnTxt") }}
                      <svg-icon class="greenIcon" icon-class="MobileFilterBtn"></svg-icon>
                    </el-button>
            </div>
            <div class="tableSearch" v-if="!mobileFlag">
                <el-form label-position="top" :model="tableSearchForm" ref="tableSearchForm">
                  <el-form-item :label="$t('followSymbol_searchSymbol')" prop="portfolioName">
                    <el-input v-model="tableSearchForm.portfolioName"></el-input>
                  </el-form-item>
                 
                  <!-- <el-form-item :label="$t('MyFollowers_Status')" prop="status">
                    <el-select v-model="tableSearchForm.status">
                      <el-option :label="$t('MyFollowers_AllStatus')" value=""></el-option>
                      <el-option :label="$t('statusOn')" value="1"></el-option>
                      <el-option :label="$t('statusOff')" value="0"></el-option>
                      <el-option :label="$t('MyFollowers_StopCopying1')" value="3"></el-option> -->
                      <!-- <el-option label="停止跟单" value="3"></el-option> 
                    </el-select>
                  </el-form-item> -->

                </el-form>
                <el-button class="serchFormBtn" @click="getData(1, pageSize,'search')" >
                  {{ $t('MyFollowers_Search') }}
                  <svg-icon icon-class="SearchIcon"></svg-icon>
                </el-button>
                <el-button class="resetFormBtn" @click="resetForm()">
                  {{ $t('MyFollowers_Reset') }}
                  <svg-icon icon-class="ResetIcon"></svg-icon>

                </el-button>
                <el-button class="serchFormBtn" style="margin-left: 20px;" @click="addSignal(true)" >
                  {{ $t('MyFollowers_AddSignalSource') }}
                </el-button>
                <el-button class="serchFormBtn" style="margin-left: 20px;" @click="getData(currentPage,pageSize)" >
                  {{ $t('ManualReplenishment_refresh') }}
                </el-button>
              </div>
            <div class="tableDiv" v-if="!mobileFlag">
              <el-table :data="tableData" border style="width: 100%" >

                <el-table-column width="80" key="0" v-if="!mobileFlag" prop="sortNum" :label="$t('MyFollowers_Number')">
                </el-table-column>
                <el-table-column prop="portfolioName" key="1" :label="$t('name')" >
                  
                </el-table-column>
                <el-table-column
                        prop="signalAccount"
                        key="2"
                        :label="$t('signal_num')">

                    </el-table-column>
                <el-table-column
                        prop="equity"
                        key="3"
                        :label="$t('MyFollowers_Equity')"
                        v-if="activeName == 'on'"
                        >
                        <template slot-scope="scope">
                            <div >
                              {{ scope.row.equity !== null  ?formatNumberFractionDigits(scope.row.equity)   : '0.00' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="balance"
                        key="4"
                        v-if="activeName == 'on'"
                        :label="$t('MyFollowers_Balance')">

                        <template slot-scope="scope">
                            <div >
                              {{ scope.row.balance !== null ? formatNumberFractionDigits(scope.row.balance) : '0.00' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="profit"
                        key="5"
                        v-if="activeName == 'on'"
                        :label="$t('MyFollowers_PL')">
                        <template slot-scope="scope">
                            <div :class="[scope.row.profit > 0 ?'greenNum':(scope.row.profit < 0?'redNum':'') ]">
                              {{ scope.row.profit !== null ? (scope.row.profit > 0 ? '+ ' + formatNumberFractionDigits(scope.row.profit) :(scope.row.profit < 0? + formatNumberFractionDigits(scope.row.profit):'0.00')):'0.00' }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="shareProfitPercent"
                        key="6"
                        :label="$t('profitSharingRatio')">
                        <template slot-scope="scope">
                            <div>
                              {{ scope.row.shareProfitPercent ? scope.row.shareProfitPercent+'%' : '0 %'}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="settlementPeriodTypeTitle"
                        key="7"
                        :label="$t('billingCycle')">
                        <template slot-scope="scope">
                            <div>
                              {{ scope.row.settlementPeriodTypeTitle ? scope.row.settlementPeriodTypeTitle : '-'}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="followCount"
                        key="8"
                        v-if="activeName == 'on'"
                        :label="$t('home_titlesymbolSubtitle3')">
                        <template slot-scope="scope">
                            <div class="clickClass" @click="toAccount(scope.row, 'follow')">
                              {{ scope.row.followCount ? scope.row.followCount : '0'}}
                            </div>
                        </template>

                    </el-table-column>
                    <el-table-column
                        key="9"
                        prop="followTotalEquity"
                        v-if="activeName == 'on'"
                        :label="$t('followNetWorth')">
                        <template slot-scope="scope">
                            <div >
                              {{ scope.row.followTotalEquity!== null ? formatNumberFractionDigits(scope.row.followTotalEquity) : '0.00' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                         key="10"
                        prop="followTotalBalance"
                        v-if="activeName == 'on'"
                        :label="$t('followBalance')"> 
                        <template slot-scope="scope">
                            <div >
                              {{ scope.row.followTotalBalance !== null ?formatNumberFractionDigits(scope.row.followTotalBalance)  : '0.00' }}
                            </div>
                        </template>

                    </el-table-column>
                    <el-table-column
                    key="11"
                        prop="followTotalProfit"
                        v-if="activeName == 'on'"
                        :label="$t('followPL')">
                        <template slot-scope="scope">
                            <div :class="[scope.row.followTotalProfit > 0 ?'greenNum':(scope.row.followTotalProfit < 0?'redNum':'') ]">
                              {{ scope.row.followTotalProfit !== null ? (scope.row.followTotalProfit > 0 ? '+ ' + formatNumberFractionDigits(scope.row.followTotalProfit) :(scope.row.followTotalProfit < 0? +formatNumberFractionDigits(scope.row.followTotalProfit) :'0.00')):'0.00' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    key="12"
                        prop="notSettlementProfit"
                        v-if="activeName == 'on'"
                        :label="$t('ToBeDivided')">
                        <template slot-scope="scope">
                            <div>
                              {{ scope.row.notSettlementProfit !== null ? formatNumberFractionDigits(scope.row.notSettlementProfit) : '0.00' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    key="13"
                        prop="completeProfit"
                        :label="$t('BeenDivided')">
                        <template slot-scope="scope">
                            <div class="clickClass" @click="toAccount(scope.row,'signalHistoricalProfit')">
                              {{ scope.row.completeProfit !== null ? formatNumberFractionDigits(scope.row.completeProfit) : '0.00' }}
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                        prop="status"
                        :label="$t('MyFollowers_Status')">
                        <template slot-scope="scope">
                         // <span v-if="scope.row.status == 0" >{{
                            $t("statusOff")
                          }}</span>
                          <span v-if="scope.row.status == 1" >{{
                            $t("statusOn")
                          }}</span>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column
                        prop="status"
                        :label="$t('MyFollowers_Status')">
                        <template slot-scope="scope">
                          <el-switch
                            v-model="scope.row.status"
                            :loading="scope.row.isSWitchLoading"
                            @change="handleSwitchChange(scope.row)"
                            :active-value="1"
                            :inactive-value="0">
                          </el-switch>
                        </template>
                    </el-table-column> -->
                    <el-table-column
                    key="14"
                        prop="currentSettlementEndTime"
                        v-if="activeName == 'on'"
                        width="150"
                        :label="$t('settlementTimeOfThisPeriod')">

                    </el-table-column>

                    <el-table-column
                    key="15"
                        prop="updateTime"
                        v-if="activeName == 'off'"
                        width="150"
                        :label="$t('signalOffTime')">
                        <template slot-scope="scope">
                            <div >
                              {{ scope.row.updateTime }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="120" key="16" :label="$t('MyFollowers_Operation')" >
                      <template slot-scope="scope">
                        <!-- {{ scope.row.insTime | dateFilter }} -->
                        <div class="operateBtns">
                          <el-button @click="toAccount(scope.row)" type="text" size="small">
                            <svg-icon class="greenIcon" icon-class="MainAllClose"></svg-icon>
                            {{ $t("MyFollowers_Details1") }}
                          </el-button>
                          <!-- <el-button @click="deleteSignal(scope.row)" type="text" size="small">
                            <svg-icon class="greenIcon" icon-class="DeleteIcon"></svg-icon>
                            {{ $t("MyFollowers_Delete") }}
                          </el-button> -->
                          <el-button   @click="settleUnbind(scope.row)"  type="text" v-if="activeName == 'on'"
                            size="small" v-loading.fullscreen.lock="fullscreenLoading">
                            <svg-icon class="greenIcon" icon-class="RelieveIcon"></svg-icon>
                            {{ $t("disabledDialogTitle") }}
                          </el-button>
                        </div>
                      </template>
                    </el-table-column>
              </el-table>
              <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')"
                :next-text="$t('MyFollowers_NexPage')" :current-page="currentPage" :page-size="pageSize"
                @current-change="handleCurrentChange" @size-change="handleSizeChange" :page-sizes="[5, 10, 20, 50, 100]"
                layout="prev, pager, next, sizes" :total="total">
              </el-pagination>
            </div>
            <div class="mobileTableList" v-show="mobileFlag">

                <el-collapse accordion @change="collapseChange">
                    <el-collapse-item v-for="(item,index) in tableData" :key="index">
                    <template slot="title">
                      <div class="mobileTableCard" @click.stop="showCollapse()">

                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('name') }}
                          </p>
                          <p class="mobileTableContent"  >
                              {{ item.portfolioName }}
                          </p>
                          </div>
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('signal_num') }}
                          </p>
                          <p class="mobileTableContent" >
                              {{  item.signalAccount  }}
                          </p>
                          </div>
                          <div class="mobileTableItem" v-if="activeName == 'on'">
                          <p class="mobileTableTitle" >
                              {{ $t('MyFollowers_Equity') }}
                          </p>
                          <p class="mobileTableContent" >
                              {{  item.equity !== null ? formatNumberFractionDigits(item.equity) : 0.00}}
                          </p>
                          </div>
                          <div class="mobileTableItem" v-if="activeName == 'on'">
                          <p class="mobileTableTitle" >
                              {{ $t('MyFollowers_Balance') }}
                          </p>
                          <p  class="mobileTableContent">
                            {{  item.balance !== null ?formatNumberFractionDigits(item.balance)  : 0.00}}
                          </p>
                          </div>
                          <div class="mobileTableItem" v-if="activeName == 'off'">
                        <p class="mobileTableTitle" >
                            {{ $t('profitSharingRatio') }}
                        </p>
                        <p class="mobileTableContent" >
                          {{ item.shareProfitPercent?item.shareProfitPercent+'%' : 0 + ' %' }}
                        </p>
                        </div>
                        <div class="mobileTableItem" v-if="activeName == 'off'">
                        <p class="mobileTableTitle" >
                            {{ $t('billingCycle') }}
                        </p>
                        <p class="mobileTableContent" >
                          {{ item.settlementPeriodTypeTitle? item.settlementPeriodTypeTitle :'-' }}
                        </p>
                        </div>
                      </div>
                      <div class="mobileTableBtns" @click.stop="showCollapse()">
                        <el-button   class="mobileBlueBtn" @click="toAccount(item)" type="text" size="small">
                          <svg-icon class="greenIcon" icon-class="MainAllClose"></svg-icon>
                          {{ $t("MyFollowers_Details1") }}
                        </el-button>
                        <el-button v-if="activeName == 'on'"  class="mobileBlueBtn" @click="settleUnbind(item)" type="text" size="small">
                          <svg-icon class="greenIcon" icon-class="RelieveIcon"></svg-icon>
                          {{ $t("disabledDialogTitle") }}
                        </el-button>
                        
                      </div>
                    </template>
                    <div class="mobileTableCard" >
                        <div class="mobileTableItem" v-if="activeName == 'on'">
                        <p class="mobileTableTitle" >
                            {{ $t('MyFollowers_PL') }}
                        </p>
                        <p :class="[item.profit > 0 ?'greenNum':(item.profit < 0?'redNum':'') , 'mobileTableContent']" >

                            {{ item.profit !== null ? (item.profit > 0 ? '+ ' + formatNumberFractionDigits(item.profit) :(item.profit < 0? formatNumberFractionDigits(item.profit):'0.00')):'0.00' }}

                        </p>
                        </div>
                        <div class="mobileTableItem" v-if="activeName == 'on'">
                        <p class="mobileTableTitle" >
                            {{ $t('profitSharingRatio') }}
                        </p>
                        <p class="mobileTableContent" >
                          {{ item.shareProfitPercent?item.shareProfitPercent+'%' : 0 + ' %' }}
                        </p>
                        </div>
                        <div class="mobileTableItem" v-if="activeName == 'on'">
                        <p class="mobileTableTitle" >
                            {{ $t('billingCycle') }}
                        </p>
                        <p class="mobileTableContent" >
                          {{ item.settlementPeriodTypeTitle? item.settlementPeriodTypeTitle :'-' }}
                        </p>
                        </div>
                        <div class="mobileTableItem" v-if="activeName == 'on'">
                        <p class="mobileTableTitle" >
                            {{ $t('home_titlesymbolSubtitle3') }}
                        </p>
                        <p class="mobileTableContent clickClass" @click="toAccount(item, 'follow')">
                            {{  item.followCount  }}
                        </p>
                        </div>
                        <div class="mobileTableItem" v-if="activeName == 'on'">
                        <p class="mobileTableTitle" >
                            {{ $t('followNetWorth') }}
                        </p>
                        <p  class="mobileTableContent" >
                          {{  item.followTotalEquity !== null ?formatNumberFractionDigits(item.followTotalEquity)  : '0.00'}}
                        </p>
                        </div>
                        <div class="mobileTableItem" v-if="activeName == 'on'">
                        <p class="mobileTableTitle" >
                            {{ $t('followBalance') }}
                        </p>
                        <p  class="mobileTableContent">
                          {{  item.followTotalBalance !== null ?formatNumberFractionDigits(item.followTotalBalance)  : '0.00'}}
                           
                        </p>
                        </div>
                        <div class="mobileTableItem" v-if="activeName == 'on'">
                        <p class="mobileTableTitle" >
                            {{ $t('followPL') }}
                        </p>
                        <p  :class="[item.followTotalProfit > 0 ?'greenNum':(item.followTotalBalance < 0?'redNum':''),'mobileTableContent' ]">
                            {{ item.profit !== null ?  (item.followTotalProfit > 0 ? '+ ' +formatNumberFractionDigits(item.followTotalProfit)  :(item.followTotalProfit < 0?formatNumberFractionDigits(item.followTotalProfit):'0.00')):'0.00' }}
                        </p>
                        </div>
                        <div class="mobileTableItem" v-if="activeName == 'on'">
                        <p class="mobileTableTitle" >
                            {{ $t('ToBeDivided') }}
                        </p>
                        <p  class="mobileTableContent">
                          {{  item.notSettlementProfit !== null ? formatNumberFractionDigits(item.notSettlementProfit) : '0.00'}}
                       
                        </p>
                        </div>
                        <div class="mobileTableItem">
                        <p class="mobileTableTitle" >
                            {{ $t('BeenDivided') }}
                        </p>
                        <p  class="mobileTableContent clickClass" @click="toAccount(item,'signalHistoricalProfit')">
                          {{  item.completeProfit !== null ?formatNumberFractionDigits(item.completeProfit) : '0.00'}}
                            
                        </p>
                        </div>
                        <!-- <div class="mobileTableItem">
                        <p class="mobileTableTitle" >
                            {{ $t('MyFollowers_Status') }}
                        </p>
                        <p  class="mobileTableContent">
                          <span v-if="item.status == 0" >{{
                            $t("statusOff")
                          }}</span>
                          <span v-if="item.status == 1" >{{
                            $t("statusOn")
                          }}</span>
                        </p>
                        </div> -->
                        <div class="mobileTableItem" v-if="activeName == 'on'">
                        <p class="mobileTableTitle" >
                            {{ $t('settlementTimeOfThisPeriod') }}
                        </p>
                        <p  class="mobileTableContent">
                            {{  item.nextSettlementTime  }}
                        </p>
                        </div>
                        <div class="mobileTableItem" v-if="activeName == 'off'">
                        <p class="mobileTableTitle" >
                            {{ $t('signalOffTime') }}
                        </p>
                        <p  class="mobileTableContent">
                            {{  item.updateTime  }}
                        </p>
                        </div>
                    </div>
                </el-collapse-item>

                </el-collapse>
                <div class="x" v-if="up && tableData.length < total">
                      <i  class="el-icon-loading "></i>
                      {{ $t('loading') }}
                    </div>
                    <div class="x" v-if="!up && tableData.length >= total">

                      {{ $t('noMoreData') }}
                    </div>
            </div>
      </div>
      </div>


      <el-dialog :title="dialogTitle" @close="cancelSignal()" :visible.sync="dialogVisible" class="signalDialog" :close-on-click-modal="false"
        width="40%" top="5vh">
        <el-form :model="signalForm" :rules="rules" ref="signalForm" :label-position="mobileFlag ? 'top' : 'left'" @submit.native.prevent label-width="150px">
          <el-form-item :label="$t('MyFollowers_SignalSourceName')"  prop="portfolioName">
            <el-input v-model="signalForm.portfolioName" maxlength="40" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('brokerName')" prop="brokerName" class="toReg">
            <el-select  v-model="signalForm.brokerName"  disabled
              >
              <el-option v-for="item in brokerList" :key="item.id" :label="item.brokerName" :value="item.id">
              </el-option>
            </el-select>
            <p><span @click="toReg()">{{ $t('NoAccountRegister2')}}</span></p>
          </el-form-item>
          <el-form-item :label="$t('server')" prop="serverId">
                <!-- <el-input v-model="signalForm.serverName" disabled></el-input> -->
                <el-select
                  v-model="signalForm.serverId"
                  class="allLengthInput"
                  
                >
                  <el-option
                    v-for="item in serverList"
                    :key="item.serverId"
                    :label="item.serverName"
                    :value="item.serverId"
                  >
                  </el-option>
                </el-select>
          </el-form-item>
          <el-form-item :label="$t('MyFollowers_ChooseMT4Account')" prop="account">
            <!-- <el-select  v-model="signalForm.account" class="drawer_100Input"
              @change="selectChange">
              <el-option v-for="item in signalForm.accountList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select> -->
            <el-input   v-model="signalForm.account" autocomplete="off" ></el-input>

          </el-form-item>
          <el-form-item  :label="$t('MyFollowers_Mt4Password')" prop="password">

            <el-input type="password" v-model="signalForm.password" autocomplete="off" show-password></el-input>

          </el-form-item>
          <el-form-item :label="$t('profitSharingRatio')" prop="shareProfitPercent">
            <el-input v-model="signalForm.shareProfitPercent" placeholder="0-100"></el-input>
            <b class="formPercent">%</b>
          </el-form-item>
          <el-form-item prop="settlementPeriodType"  >
            <span slot="label">
              <span class="label-box">
                <span> {{ $t("billingCycle") }} </span>
                <a
                  href="javascript:;"
                  @click.stop="
                    labelIconClick(
                      $t('billingCycle'),
                      $t('basedOnCalendarDaysAndMore')
                    )
                  "
                >
                <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
                <div class="labelIconTxt">
                  <i class="el-icon-caret-top labelIconBack"></i>{{ $t("basedOnCalendarDaysAndMore") }}
                </div>
              </span>
            </span>
            <el-select  v-model="signalForm.settlementPeriodType"  @change="changePeriod">
              <el-option v-for="item in settlementPeriodTypeList" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('firstSettlementTime')" v-if="signalForm.status == 1"  prop="firstSettlementTime">
            <el-input v-model="signalForm.firstSettlementTime" disabled autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="followDirection" :label="$t('MyFollowers_CopyDirection')">
            
            <el-radio-group v-model="signalForm.followDirection">
              <el-radio :label="0"> {{ $t("MyFollowers_Copy") }} </el-radio>
              <el-radio :label="1">
                {{ $t("MyFollowers_ReverseCopy") }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item prop="status" :label="$t('enableStatus')">
            <el-switch
              v-model="signalForm.status"
              active-value="1"
              inactive-value="0">
            </el-switch>
          </el-form-item> -->
          <el-form-item prop="status" :label="$t('profitSharingInstructions')">
            <div>
              {{ $t("profitSharingInstructionsOne") }}
            </div>
            <div>
              {{ $t("profitSharingInstructionsTwo") }}
            </div>
            <div>
              {{ $t("profitSharingInstructionsThree") }}
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="confirmDialogBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="signalSubmit()">{{
            $t('MyFollowers_confirm')
          }}</el-button>
        </div>
      </el-dialog>

      <el-dialog :title="$t('disabledDialogTitle')" @close="cancelSignal()"  :visible.sync="isDisabledDialog"  :close-on-click-modal="false"
        width="40%" top="5vh">
        <el-form class="settleUnbindForm" :model="settleUnbindForm" :rules="rules" ref="settleUnbindForm" :label-position="mobileFlag ? 'top' : 'left'" @submit.native.prevent label-width="150px">
          <el-form-item :label="$t('startTimeOfThisPeriod')"  prop="currentSettlementBeginTime">
            <el-input disabled v-model="settleUnbindForm.currentBeginTime" maxlength="40" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('followCustomNum')" prop="followUserCount" >
            <el-input disabled v-model="settleUnbindForm.followUserCount" maxlength="40" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('profitSharingRatio')" prop="sharePercent">
            <el-input disabled v-model="settleUnbindForm.sharePercent" placeholder="0-100"></el-input>
            <b class="formPercent">%</b>
          </el-form-item>
          <el-form-item :label="$t('shareMoneyNum')" prop="shareTotalProfit">
            <el-input disabled v-model="settleUnbindForm.shareTotalProfit" placeholder="0-100"></el-input>
            
          </el-form-item>
        </el-form>
        <p class="settleUnbindTips" v-if="settleUnbindForm.settlementStatus == 1">
          {{$t('settleUnbindTips') }}
        </p>
        <div slot="footer" class="dialog-footer">
          <el-button class="confirmDialogBtn" v-if="settleUnbindForm.settlementStatus !== 1" v-loading.fullscreen.lock="fullscreenLoading" @click="settleUnbindSubmit()">{{
            $t('MyFollowers_confirm')
          }}</el-button>
        </div>
      </el-dialog>
    </div>

    <el-drawer
              class="mobileSearchDrawer"
              :visible.sync="mobileSearchDrawer"
              direction="ttb">
              <div class="mobileFilterReturnBtnDiv">
                <el-button  class="mobileFilterReturnBtn" @click="mobileSearchDrawer = false">
                    {{ $t('MyFollowers_Search') }}
                    <i class="el-icon-arrow-up"></i>
                </el-button>
              </div>
              
              <el-form label-position="top" :model="tableSearchForm" ref="tableSearchForm">
                  <el-form-item :label="$t('followSymbol_searchSymbol')" prop="portfolioName">
                    <el-input v-model="tableSearchForm.portfolioName"></el-input>
                  </el-form-item>
                 
                  <el-form-item :label="$t('MyFollowers_Status')" prop="status">
                    <el-select v-model="tableSearchForm.status">
                      <el-option :label="$t('MyFollowers_AllStatus')" value=""></el-option>
                      <el-option :label="$t('statusOn')" value="1"></el-option>
                      <el-option :label="$t('statusOff')" value="0"></el-option>
                      <!-- <el-option :label="$t('MyFollowers_StopCopying1')" value="3"></el-option> -->
                      <!-- <el-option label="停止跟单" value="3"></el-option> -->
                    </el-select>
                  </el-form-item>

                </el-form>
                <div class="mobileFilterBottomBtns">
                  <el-button class="resetFormBtn" @click="resetForm()">
                      {{ $t('MyFollowers_Reset') }}
                  </el-button>
                  <el-button class="serchFormBtn" @click="getData(1, pageSize,'search')">
                      {{ $t('MyFollowers_Search') }}
                  </el-button>
                </div>
            </el-drawer>

    <PopupTips :popupVisible='isPopupVisible' :icon="iconImg" :title="$t('MyFollowers_prompt')"
      @confirmPopupTips="confirmPopup"
      @cancelPopupTips="cancelPopup"
      ref="popupTipsRef"
      :content="$t('MyFollowers_deleteSignal')" :btnType="2" />
  </div>
</template>

<script>
// @ is an alias to /src
import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
import { concatParams, concatTableData } from "../../plugins/common.js";
import { mapState } from "vuex";
import PopupTips from '@/components/moudule/PopupTips.vue'
import BScroll from '@better-scroll/core'
  import Pullup from '@better-scroll/pull-up'

  BScroll.use(Pullup)



export default {
  name: 'MySignal',
  components: {
    PopupTips
  },
  computed: {
    ...mapState(["checkPortfolio", "userInfo", "token", 'mobileFlag']),
  },
  data() {
    var checkPortfolioName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("MyFollowers_checkSignalName")));
      } else {
        var reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
        if (!reg.test(value)) {

          callback(new Error(this.$t("MyFollowers_checkSignalNameNotSpecial")));
        } else {
          callback();
        }
      }
    };
    var checkPercent = (rule, value, callback) => {
      
      var reg = /^([0-9][0-9]{0,1}|100)$/
      if (!reg.test(value)) {

        callback(new Error(this.$t("numberOneToHundred")));
      } else {
        callback();
      }
      
    };
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      pageSize: 5,
      dialogVisible: false,
      signalForm: {
        portfolioName: '',
        settlementPeriodType: 2,
        brokerName:'',
        serverId:'',
        account	:'',
        password: '',
        shareProfitPercent: 0,
        followDirection: 0,
        status:'1',
        firstSettlementTime:''
      },
      rules: {
        portfolioName: [
          { validator: checkPortfolioName, trigger: "change" },
        ],
        serverId: [
          {
            required: true,
            message: this.$t("serverNotNull"),
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: this.$t("MyFollowers_checkMt4Account"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("MyFollowers_checkMt4AccountPass"),
            trigger: "blur",
          },
        ],
        shareProfitPercent: [
          { required: true, message: this.$t("PaymentOfDividendsNotNull"), trigger: 'blur' },
          {
            validator: checkPercent, 
            trigger: "blur",
          },
        ],
        settlementPeriodType: [
          {
            required: true,
            message: this.$t("settlementPeriodTypeNotNull"),
            trigger: "blur",
          },
        ],
      },
      checkRow: {},
      dialogTitle: '',
      btnTxt: '',
      isPopupVisible: false,
      iconImg:require('../../assets/images/NotifyWarning.svg'),
      headerData:[
        {

        }
      ],
      up:false,
      firstOpen:false,
      serverList:[],
      settlementPeriodTypeList:[],
      tableSearchForm: {
        portfolioName:'',
        status: '1'
      },
      brokerList:[],
      mobileSearchDrawer:false,
      fullscreenLoading:false,
      switchLoading:false,
      isDisabledDialog:false,
      settleUnbindForm:{},
      activeName:'on'
    }
  },
  created() {
    this.$store.commit('setChooseSignal', '')
    this.getData(this.currentPage, this.pageSize)
    this.getServeList()
    this.getSettlementPeriodTypeList()
    
  },
  methods: {
    formatNumberFractionDigits,
    openSearch(){
        this.mobileSearchDrawer = true
      },
    collapseChange(){
      this.$nextTick(() => {
            if(!this.firstOpen){
              this.scroll.maxScrollY = this.scroll.maxScrollY - 150
              this.firstOpen = true
            }else{
              this.scroll.maxScrollY = this.scroll.maxScrollY + 150
              this.firstOpen = false
            }
        });

    },
    showCollapse(){

    },
    scrollFn(){
        this.$nextTick(() => {

            if (!this.scroll) {
                this.scroll = new BScroll(this.$refs.bscroll, {
                    click: true,
                    scrollY: true,
                    probeType: 3
                });
            }else{
                this.scroll.refresh();
            }

            //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
            this.scroll.on('touchEnd', (pos) => {
                //上拉加载 总高度>下拉的高度+10 触发加载更多
                if(this.scroll.maxScrollY>pos.y + 50){
                    // console.log("加载更多")
                    this.up=true;
                    setTimeout(()=>{
                    //使用refresh 方法 来更新scroll  解决无法滚动的问题
                        if(this.tableData.length < this.total){
                          this.currentPage = this.currentPage + 1
                          this.getData(this.currentPage,this.pageSize,'noRefresh')
                          this.scroll.refresh();
                          this.up=false;
                        }else{
                          this.up=false
                        }

                   },1000)

                }
                // console.log(this.scroll.maxScrollY+"总距离----下拉的距离"+pos.y)
            })

            // console.log(this.scroll.maxScrollY)
        });
    },
    toAccount(row,tabName) {
      this.$store.commit('setPortfolio', row)
      this.$store.commit('setChooseSignal', row.portfolioName)
      this.$store.commit('setActiveTab', 'signalBaseInfo')
      if(tabName){
        this.$store.commit("setActiveTab", tabName);
      }
      this.$router.push({ path: '/MyAccount'})
    },
    getData(pageIndex, pageSize,tag) {
      if(tag == 'search'){
        this.currentPage = 1
      }
      if(!this.mobileFlag || tag !== 'noRefresh'){
        this.tableData = [];

      }
      var params = {
        pageIndex: pageIndex,
        pageSize: pageSize
      }
      if(this.tableSearchForm.portfolioName){
        params.portfolioName = this.tableSearchForm.portfolioName
      }
      
      params.status = this.tableSearchForm.status
      
      this.$axios.post('/Api/Portfolio/GetListPage', params).then((res) => {
        let dt = res.data
        if (dt.isSuccess) {
          this.total = dt.result.total
          if(this.mobileFlag && tag == 'noRefresh'){
            dt.result.pageList.forEach((item) =>{
              this.tableData.push(item)
            })


          }else{
            this.tableData = concatTableData(
              dt.result.pageList,
              dt.result.total,
              this.currentPage,
              this.pageSize
            );
          }

          this.fullscreenLoading = false;
          if(this.mobileFlag){
            this.scrollFn()
          }

          this.tableData.forEach((item) => {
            item.isSWitchLoading = false;//默认设为false
          });
          // this.tableData = concatTableData(dt.result.pageList, dt.result.total, this.currentPage, this.pageSize)
        }
      }).finally(() => {
              this.fullscreenLoading = false;
            });
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getData(this.currentPage, this.pageSize)
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData(this.currentPage, this.pageSize);
    },
    addSignal() {
      this.dialogTitle = this.$t('MyFollowers_AddSignalSourceDialog')
      this.btnTxt = this.$t('MyFollowers_Add')
      this.checkRow = {}
      this.dialogVisible = true
      this.changePeriod(2)
    },
    cancelSignal() {
      // console.log(this.signalForm)
      this.$refs.signalForm.resetFields();

      this.dialogVisible = false
    },
    signalSubmit() {
      this.$refs.signalForm.validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true
          var params = {}
          if (this.checkRow.id) {
            params = {
              id: this.checkRow.id,
              ...this.signalForm
            }
          } else {
            params = this.signalForm
          }

          this.$axios.post('/Api/Portfolio/AddPortfolio', params).then((res) => {
            let dt = res.data
            if (dt.isSuccess) {
              if (this.checkRow.id) {
                this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});


              } else {
                this.$notify.success({title: this.$t('nofity_success'), message:this.$t('mainAddSuccess')});



              }
              //               this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

              this.getData(this.currentPage, this.pageSize)
              this.dialogVisible = false
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          }).finally(() => {
              this.fullscreenLoading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editSignal(row) {
      this.dialogTitle = this.$t('MyFollowers_ModifyName')
      this.btnTxt = this.$t('MyFollowers_Modify')
      this.checkRow = row
      this.$axios.get('/Api/Portfolio/Get?id=' + row.id).then((res) => {
        let dt = res.data
        if (dt.isSuccess) {
          this.dialogVisible = true
          this.$nextTick(function () {
            this.signalForm.portfolioName = dt.result.portfolioName
          })
          // this.tableData = concatTableData(dt.result,10)
        }
      })
      // this.addSignal(row.id)
    },
    deleteSignal(row) {
      // console.log(row)
      // this.$confirm(this.$t('MyFollowers_deleteSignal'), this.$t('MyFollowers_prompt'), {
      //   confirmButtonText: this.$t('MyFollowers_confirm'),
      //   cancelButtonText: this.$t('MyFollowers_Cancel'),
      //   type: 'warning'
      // }).then(() => {
        this.isPopupVisible = true
        this.checkRow = row
      // })
    },
    cancelPopup(val){
      this.isPopupVisible = val
    },
    confirmPopup(val){
      this.isPopupVisible = val
      const loading = this.$loading({
          lock: true
        });
        var params = { id: this.checkRow.id }
        this.$axios.get('/Api/Portfolio/Delete' + concatParams(params)).then((res) => {
          let dt = res.data
          if (dt.isSuccess) {
            this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_deleteSuccess')});
            const totalPage = Math.ceil((this.total - 1) / this.pageSize) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage

            this.getData(this.currentPage, this.pageSize)
          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        }).finally(()=>{
          loading.close()
        });
    },
    arraySpanMethod(){
      if(tableData.length !== 0){
        if (columnIndex === 0) {
              if (rowIndex % 2 === 0) {
                return {
                  rowspan: 2,
                  colspan: 2
                };
              } else {
                return {
                  rowspan: 2,
                  colspan: 1
                };
              }
            }
        }
    },
    getServeList(){
      this.$axios.get("/Api/invite/BrokerList").then((res) => {
        let dt = res.data;
        if (dt.isSuccess) {
          // this.mainAccountDrawer = true
          this.brokerList = dt.result;
          // console.log(this.brokerList)
          this.signalForm.brokerName = this.brokerList[0].id
          this.goRegUrl = dt.result[0].registUrl
          this.serverList = dt.result[0].serverList
          // this.signalForm.portfolioName = dt.result.portfolioName
          // this.tableData = concatTableData(dt.result,10)
        }
      });
    },
    getSettlementPeriodTypeList(){
      this.$axios.get("/Api/Portfolio/GetSettlementPeriodList").then((res) => {
        let dt = res.data;
        if (dt.isSuccess) {
          this.settlementPeriodTypeList = dt.result;
        }
      });
    },
    // mt4选择
    selectChange(val) {
        // console.log(val)
        this.brokerList.forEach((item,index) =>{
          if(item.id == val){
            this.serverList = item.serverList
            this.goRegUrl = item.registUrl
          }
        })
    },
    resetForm() {
      this.$refs.tableSearchForm.resetFields();
      this.currentPage = 1
      this.getData(1,this.pageSize)
    },
    toReg(){
      window.open('https://crm.ecmarkets.co/zh-CN/sign/register')
    },
    changePeriod(value){
      this.$axios.get("api/Portfolio/SettlementTypeTitle?type="+ value).then((res) => {
        let dt = res.data;
        if (dt.isSuccess) {
          this.signalForm.firstSettlementTime = dt.result;
        }
      });
    },
    settleUnbindSubmit(){
      this.$confirm(
        this.$t("settleUnbindConfirmTipsSignal"),
        this.$t("MyFollowers_prompt"),
        {
          confirmButtonText: this.$t("MyFollowers_confirm"),
          cancelButtonText: this.$t("MyFollowers_Cancel"),
          type: "warning",
        }
      ).then(() => {
        this.fullscreenLoading = true;
        this.$axios
          .get("/Api/Portfolio/SettltementPortfolio?id="+this.checkRow.id)
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});
              this.getData(this.currentPage, this.pageSize);
              this.isDisabledDialog = false
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          }).finally(() => {
            this.fullscreenLoading = false;
          });
          
      });
    },
    handleClick(tab){
      this.fullscreenLoading = true
      if(tab.name == 'on'){
        this.tableSearchForm.status = 1
      }else{
        this.tableSearchForm.status = 0
      }
      this.getData(1,this.pageSize)
    },
    settleUnbind(row){
      this.checkRow = row
      this.fullscreenLoading = true
        this.$axios.get("/Api/Portfolio/SettltementPortfolioDetail?id="+row.id).then((res) => {
        let dt = res.data;
        if (dt.isSuccess) {
            this.isDisabledDialog = true
            this.settleUnbindForm = dt.result;
            this.settleUnbindForm.sharePercent = formatNumberFractionDigits(this.settleUnbindForm.sharePercent)
            this.settleUnbindForm.shareTotalProfit = formatNumberFractionDigits(this.settleUnbindForm.shareTotalProfit)

          }
        }).finally(() => {
          this.fullscreenLoading = false;
        });
    }
  }
}
</script>
<style lang="less" scoped>
.MySignal {
  .portfolioClick {
    cursor: pointer;
    color: rgb(59, 158, 238);
  }
  .label-box{
    justify-content: flex-start;
    .labelIconTxt{
      width: 240px;
      right: -28%;
    }
  }
  .formPercent {
        right:22% ;
    }
  .tableSearch{
    display: flex;
    flex-direction: row;
    align-items: center;
    button{
      margin-top: 24px
    }
  }
  .clickClass{
    color: #4d71ff!important;
    cursor: pointer;
  }
  .settleUnbindForm {
    .formPercent {
      right: 2%;
    }
  }
}

.tabDiv{
  margin-top:40px
}

.settleUnbindTips{
  margin: 30px 0;
  border-top: 1px solid #d0d0d0;
  padding-top: 20px;
  color: #e35972;
}

@media screen and (max-width: 767px) {
  .PageCommon{
    .pageTitle{
      justify-content: flex-end;
    }
  }

        .confirmDialogBtn{
            padding:8px 10px ;
            font-size: 12px;
            line-height: 15px;
        }
  .tabDiv{
    margin-top:20px
  }
}
</style>
